@import "../mixins/projects";
@import "../mixins/media";
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/

/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole{
  padding-inline: $padding-sp;
  @include media_desktop {
    padding-inline: $padding-pc;
  }
  & &__navlist {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    border-color: #D0D0D0;
    border-style: solid;
    border-width: 1px 0 0 1px;
    margin-bottom: 32px;
    padding: 0;
    list-style: none;
  }

  & &__item{
    display: flex;
    width: 50%;
    border-color: #D0D0D0;
    border-style: solid;
    border-width: 0 1px 1px 0;
    text-align: center;
    font-weight: bold;
    @include media_desktop {
      width: 20%;
    }
    &:hover{
      background: #f5f7f8;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: auto;
      padding: 16px 10px;
    }
  }
  .active {
    a {
      background: $darkBrown;
      color: #fff;
    }
  }
}

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/

/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg{
  @include mypageContainer;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  @include borderBottom;

}

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole{
  padding-inline: $padding-sp;
  @include media_desktop {
    padding-inline: $padding-pc;
  }
  & &__header {
    margin-bottom: 16px;
  }
  & &__detail {
  }
  & &__itemList {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    margin-inline: -8px;
    padding: 0;
    list-style: none;
    @include media_desktop {
      margin-inline: -16px;
    }
  }
  & &__item{
    @include hover_link-img;
    margin-bottom: 8px;
    width: 50%;
    position: relative;
    box-sizing: border-box;
    padding: 0 8px;
    @include media_desktop(){
      width: 25%;
      padding-inline: 16px;
    }
    &-image {
      margin-bottom: 10px;
      text-align: center;
    }
    img{
      width: auto;
      max-height: 100%;
    }
    .ec-closeBtn--circle {
      position: absolute;
      right: 16px;
      top: 8px;
      @include media_desktop {
        right: 24px;
      }
      .ec-icon img{
        width: 1em;
        height: 1em;
      }
    }
  }
  & &__itemThumb {
    display: block;
    height:auto;
    margin-bottom: 8px;
  }
  & &__itemTitle{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 2px;
  }
  & &__itemPrice{
    font-weight: bold;
    margin-bottom: 0;
  }

}
