@import "../mixins/media";


/* --------------------------------
	トップページ 20220801
-------------------------------- */

.hnb_top_2208 {
  @include hover_link;

  a {
    color: #333333;
  }
  p {
    margin: 1em 0;
  }
  section {
    margin-bottom: 80px;
  }

  h2 {
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    font-family: YuMincho, "Yu Mincho", "Hiragino Mincho ProN", "serif";
    font-weight: normal;
    font-size: 3.4rem;
    text-align: center;
    @include media_desktop {
      text-align: left;
    }
    span {
      display: block;
      margin-left: 0;
      font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
      @include media_desktop {
        display: inline;
        margin-left: 1em;
        font-size: 2rem;
      }
    }
  }

  h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    font-weight: bold;
  }

  .gl {
    background: #F5F5F5;
    padding: 5%;
    @include media_desktop {
      background: none;
      padding: 0;
    }
  }

  p.top_btn {
    max-width: 80%;
    margin: 40px auto;
    @include media_desktop {
      max-width: 35%;
    }
    a {
      display: block;
      border: 1px solid #333;
      padding: 10px;
      background: #fff;
      text-align: center;
    }
  }

  .main {
    position: relative;
    margin-bottom: 1em;

    .on {
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 54%;
      margin: 0;
      color: #fff;
      font-family: YuMincho, "Yu Mincho", "Hiragino Mincho ProN", "serif";
      width: max-content;
      @include media_desktop {
        left: 6%;
        top: 35%;
        transform: none;
      }

      .ttl {
        font-size: 2.6rem;
        font-weight: bold;
        margin: 0;
        @include media_desktop {
          font-size: 2.8rem;
        }
      }

      .en {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 20px;
        @include media_desktop {
          margin-top: 10px;
        }
      }

      .more {
        border: 1px solid #fff;
        padding: 5px 60px;
        width: max-content;
        margin: 0 auto;
      }
    }
  }

  .item {
    @include media_desktop {
      position: relative;
    }
    .text {
      margin: 5px 0 1em;
      @include media_desktop {
        //background: url(/template/default/img/hnb/top/icon.jpg) no-repeat 0 5px;
        padding-left: 1em;
      }
      &.on {
        width: 73%;
        background: none;
        padding: 0;
        margin: 0;
        font-family: YuMincho, "Yu Mincho", "Hiragino Mincho ProN", "serif";
        font-size: 1.7rem;
        font-weight: bold;
        color: #333;
        @include media_desktop {
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, -50%);
          width: 100%;
          font-size: 1.8rem;
          text-align: center;
          color: #fff;
          &.two {
            bottom: -10px;
          }
        }
        span {
          display: block;
          margin-bottom: 1em;
          font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
          font-weight: normal;
          font-size: 1.4rem;
          color: #333;
          @include media_desktop {
            margin-bottom: 10px;
            font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
            color: #fff;
          }
        }
      }
    }
  }

  .item.pick {
    position: relative;

    .on {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 33%;
      left: 50%;
      width: max-content;
      margin: 0;
      padding: 10px 10px;
      border: 1px solid #fff;
      text-align: center;
      color: #fff;
      @include media_desktop {
        top: 40%;
        font-size: 1.8rem;
      }
      @media screen and (min-width: 1000px) {
        top: 45%;
        padding: 10px 20px;
        font-size: 2rem;
      }
    }

    br.sp {
      display: block;
      @media screen and (min-width: 1000px) {
        display: none;
      }
    }
  }

  .flex2 {
    @include media_desktop {
      display: flex;
      gap: 4%;
    }
    p {
      width: 100%;
      @include media_desktop {
        width: 50%;
      }
    }
    &.store {
      display: flex;
      gap: 4%;

      span {
        display: block;
        margin-top: 1em;
        @include media_desktop {
          margin-top: 0;
        }
      }
    }
  }

  .flex2wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 4%;

    .item {
      width: 48%;
    }
  }

  .flex3wrap {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid #ccc;
    @include media_desktop {
      display: flex;
      flex-wrap: wrap;
      gap: 4%;
      margin-top: 0;
      padding-top: 0;
      border: none;
    }

    .item {
      width: 100%;
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid #ccc;
      @include media_desktop {
        width: 30.666666%;
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;
      }
      a {
        width: 100%;
        display: flex;
        gap: 5%;
        align-items: center;
        @include media_desktop {
          display: block;
        }
      }
      .img {
        width: 22%;
        @include media_desktop {
          width: 100%;
        }
      }
    }
  }

  .flex4 {
    display: block;
    @include media_desktop {
      display: flex;
      gap: 4%;
      justify-content: space-between;
    }
    .other_item {
      width: 100%;
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid #ccc;
      //text-align: left;
      font-family: YuMincho, "Yu Mincho", "Hiragino Mincho ProN", "serif";
      //font-weight: normal;
      @include media_desktop {
        margin-bottom: 0;
        padding-bottom: 0;
        border: 1px solid #ccc;
        text-align: center;
      }
      .text {
        width: 73%;
        margin: 1.6rem 0;
        font-family: YuMincho, "Yu Mincho", "Hiragino Mincho ProN", "serif";
        font-size: 1.7rem;
        font-weight: bold;
        @include media_desktop {
          width: auto;
          font-size: 1.6rem;
          font-weight: normal;
        }
        span {
          display: block;
          margin-bottom: 0;
          font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
          font-size: 1.2rem;
          @include media_desktop {
            margin-bottom: 10px;
          }
        }
        &.br span {
          height: auto;
          padding-top: 0;
          @include media_desktop {
            height: 3em;
            padding-top: 1em;
          }
        }
      }

      .img {
        width: 22%;
        margin-top: 1em;
        @include media_desktop {
          width: 100%;
          margin-top: 0;
        }
      }

      a {
        width: 100%;
        display: flex;
        gap: 5%;
        align-items: center;
        @include media_desktop {
          display: inline-block;
        }
      }
    }
  }

  .flex4wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
    .item {
      width: 48%;
      @include media_desktop {
        width: 22%;
      }
      img {
        display: block;
        width: 180px;
        height: 180px;
        object-fit: cover;
      }
      .text {
        margin-bottom: 1em;
        padding: 0;
        background: none;
        font-weight: bold;
      }
    }
  }

  .news_area {
    h2 {
      margin-bottom: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #ccc;
      line-height: 1.5;
      text-align: center;
      @include media_desktop {
        line-height: 1;
        margin-bottom: 15px;
      }
      span {
        margin: 0;
      }
    }


    dl {
      display: block;
      width: 100%;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #ccc;
      @include media_desktop {
        display: flex;
        gap: 5%;
      }
    }

    dt {
      width: 100%;
      @include media_desktop {
        width: 8%;
      }
    }

    dd {
      width: 100%;
      font-weight: normal;
      @include media_desktop {
        width: 87%;
      }
      a {
        text-decoration: underline;
      }
    }
  }

}
