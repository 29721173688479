@import "../mixins/projects";
@import "../mixins/media";

.band_banner {
  margin: 5px auto 0;
  @include media_desktop {
    margin-top: 0;
    max-width: $max-width;
  }

  a:hover img{
    opacity: 0.7;
    transition: all 1.0s;
  }
}
