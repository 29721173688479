@import "../mixins/media";

/* --------------------------------
	サイズ別カテゴリ表
-------------------------------- */
.sizecat-sp {
  display: block;
  @include media_desktop {
    display: none;
  }
}
.sizecat-pc {
  display: none;
  @include media_desktop {
    display: block;
  }

  tr:nth-child(1) {
    background-color: #990000;
    color: #fff;
    padding: 0px 0px 0px 20px;
  }

  tr:nth-child(2) {
    background-color: #ebebeb;
  }

  > table > tr > td {
    background-color: #990000;
  }

  td {
    border: 1px solid #ccc;
    min-width: 50px;
    padding: 4px;
    font-size: 1.5rem;
  }

  td:nth-child(1) {
    background-color:#ebebeb;
  }

}
