@import "../mixins/projects";
@import "../mixins/media";

/*--------------------------------
2022.11.28 header刷新 henobu
---------------------------------*/
/*ナビ*/

#hover-click2.hnb2211 {
  position: relative;
  height: 48px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0;
  }
  a {
    color: #777777;
  }
  &.headnav {
    .mouse-over {
      float: left;
      display: flex;
      width: 20%;
      height: 50px;
      border-right: 1px solid #ccc;
      font-size: 1.2rem;
      transition-property: border-bottom;
      transition-duration: 0.25s;
      transition-delay: 0.05s;

      @include media_desktop {
        width: 19.999%;
        height: auto;
        padding: 10px 0;
        border-left: 1px solid #ccc;
        border-bottom: 3px solid transparent;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.2;
      }
      &:hover {
        border-bottom-color: #000;
        background: #F5F5F5;
        @include media_desktop {
          .open-4 {
            display: block;
          }
        }
      }
      &:last-child {
        border-right: none;
        @include media_desktop {
          border-right: 1px solid #ccc;
        }
      }
      > a {
        margin: auto;
        pointer-events: none;
        text-align: center;
        @include media_desktop {
          pointer-events: all;
        }
      }
      span {
        font-size: 1.2rem;
      }
    }

    .open-4 {
      display: none;
      z-index: 9999;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-width: $max-width;
      padding: 0px;
      background: #e0e0e0;
      padding-top: 0px;
    }

    .area {
      min-height: 180px;
      padding: 20px 20px 10px 20px;
      margin-top: -2px;
      background: #F5F5F5;

      @media screen and (min-width: 820px) {
        margin-top: 0;
        padding: 20px 80px 10px;
      }

      h2 {
        font-size: 20px;
        font-family: YuMincho, "Yu Mincho", "Hiragino Mincho ProN", "serif";
        text-align: left;
        margin: 0 0 20px 0;
        font-weight: bold;
      }

      h3 {
        font-size: 16px;
        margin: 0 0 15px 0;
        text-align: left;
        border-bottom: 2px solid #333;
        padding-bottom: 10px;

        &.search {
          background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images//header/icon_s.png) no-repeat 0 3px;
          padding-left: 1.5em;
          display: block;
        }
      }

      a {
        color: #777777;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .flex2, .flex3, .flex4 {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
  }

  .flex2 {
    &.just_f {
      justify-content: flex-start;
      gap: 50px;
    }

    .box {
      width: 50%;
    }
  }

  .flex3 .box {
    width: 33.33%;
  }

  .flex4 .box {
    width: 25%;
  }

  .w60 {
    width: 60% !important;
  }

  .w40 {
    width: 40% !important;
  }

  .pdt1em {
    padding-top: 60px !important;
  }

  .box {
    .mb40 {
      margin-bottom: 40px;
    }
    .name {
      font-size: 1.4rem;
    }
    .bnr_list .name {
      //width: 50%;
    }
    .bnr li .name {
      text-align: center;
      margin: 10px auto 15px;
    }
    .list li a {
      background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images//header/icon_y.png) no-repeat 0 center;
      padding-left: 1em;
      display: block;
      margin-bottom: 10px;
      font-size: 14px;
    }
    .bnr_list li {
      margin-bottom: 10px;
      a {
        display: flex;
        align-items: center;
      }
      .name {
        width: 50%;
        margin-left: 10px;
      }
    }
    .btn_list li a {
      background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images//header/icon_y.png) no-repeat 1em center #fff;
      display: block;
      margin-bottom: 15px;
      font-size: 14px;
      padding: 8px 20px 8px 30px;
    }
    .flex2w {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 2%;

      li {
        width: 48%;
      }
    }

    //a:hover {
      //opacity: 0.7;
    //}

  }
}
