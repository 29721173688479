@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole{
  padding-top: 12px;
  padding-bottom: 64px;
  margin-top: 36px;
  background: #f8f8f8;
  border-top: 1px solid #e8e8e8;

  @include media_desktop(){
    padding-top: 40px;
    margin-top: 80px;
  }

  a {
    color: #444;
    &:hover {
      color: #686868;
    }
  }
  & &__inner{
    @include media_desktop {
      @include container;
    }
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi{
  margin: 0 0 1.6rem;
  padding: 0;
  list-style: none;
  text-align: center;

  & &__link{
    display: inline-block;
    margin: 0;
    padding: 0;
    a {
      display: inline-block;
      padding: 0 8px 8px;
    }
  }
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle{
  text-align: center;

  & &__logo{
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @include reset_link();

    a{
      font-size: 22px;
      color: inherit;
      @include media_desktop {
        font-size: 24px;
      }

    }

    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }
    }
  }
  & &__copyright{
    color: #000;
    font-size: 1rem;
    margin-bottom: 1.6rem;

    @include media_desktop {
      margin-top: 88px;
      font-size: 1.6rem;
    }
  }
}
