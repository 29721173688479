@import "../mixins/media";
/*
 商品一覧
 カテゴリーコンテンツ
 */

.category_content {
  padding-bottom: 15px;
  @include clearfix;
  @include media_desktop {
    padding-bottom: 16px;
  }
  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left;
  }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-inline: 8px;
    @include media_desktop {
      padding-inline: 16px;
    }
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-12 {
    width: 100%;
  }
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    @include media_desktop {
      float: left;
    }
  }
  .col-sm-4 {
    @include media_desktop {
      width: 33.33333333%;
    }
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
    font-weight: bold;
  }
  h2 {
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    font-size: 1.6rem;
    font-weight: bold;
    @include media_desktop {
      font-size: 2.4rem;
    }
  }
  h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
  }
  h4 {
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
  }
  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  ul, ol {
    list-style: none;
    margin: 0; padding: 0;
  }
  dl, dt, dd, li {
    margin: 0;
    padding: 0;
  }
  .catebox {
    padding: 10px 0 0 0 ;
  }
  .catebox2 {
    padding: 10px 0 0 0;
    display:table;
  }
  .category {
    padding: 10px;
  }
  .colorcate {
    position: relative;
    float: left;
    width: 50%;
    padding: 0 8px 8px;
    @include media_desktop {
      width: 16.6666666%;
      padding-inline: 8px;
    }
    &:hover img {
      opacity:0.6;
    }
  }
  .item_photo {
    display: block;
    margin: 0 0 8px;
    text-align: center;
    @include media_desktop {
      margin: 0 0 15px;
    }
    img {
      width: 100%;
    }
  }
  .text-warning {
    color: $text-beige !important;
  }
  .item_name {
    color: $black;
    margin: 0 0 8px;
    font-weight: normal;
  }
  .category_description {
    float: left;
    width: 100%;
    position: relative;
    @include media_desktop {
      padding-bottom: 15px;
    }
  }
  .category_photo {
    position: relative;
    float: left;
    width: 100%;
    padding: 8px;
    @include media_desktop {
      padding-top: 26px;
      width: 30%;
    }
  }
  .category_txt {
    position: relative;
    float: left;
    display: block;
    width: 100%;
    padding: 0 8px 8px;
    @include media_desktop {
      width: 70%;
    }
  }
  .category_photo2 {
    position: relative;
    float: left;
    width: 100%;
    padding: 8px;
    @include media_desktop {
      padding-top: 26px;
      width: 50%;
    }
  }
  .category_txt2 {
    position: relative;
    float: left;
    display: block;
    width: 100%;
    padding: 0 8px 8px;
    @include media_desktop {
      width: 50%;
    }
  }


}



/* --------------------------------
 サイズセレクト
-------------------------------- */

.select-rug {
  width: 100%;
  font-size: 16px;
  font-family: "Segoe UI", Verdana, "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  padding-bottom: 50px;
}

.select-rug button {
  width: 130px;
  float: right;
  margin-top: 20px;
}

.select-rug select {
  width: 76%;
  left: 65px;
}

.select-left {
  width: 100%;
  margin-bottom: 10px;
}

.select-right {
  width: 100%;
}

// Block/sizecate.twig
.sizecate {
  font-weight: bold;
}

// user_data/categorytop.twig
.sizecate2 {
  position: relative;
  float: left;
  width: 50%;
  padding: 0 8px 8px;
  @include media_desktop {
    width: 33.33333333%;
    //background-color:#ffffff;
    padding-inline: 16px;
  }
}

.textlink {
  text-align: center;
  width: 90%;
  float: left;
}
.square_btn {
  padding: 0.5em 1em;
  text-decoration: none;
  color: #555;
  font-weight: bold;
  width: 100%;
  vertical-align: middle;
  font-size: small;
  height: 47px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.square_btn2 {
  padding: 0.5em 1em;
  text-decoration: none;
  background: #f7f7f7;
  color: #555;
  font-weight: bold;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
  width: 100%;
  vertical-align: middle;
  font-size: small;
  height: 47px;
  align-items: center;
  display: flex;
  justify-content: center;
}
