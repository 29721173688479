@import "../mixins/media";

/* --------------------------------
	カテゴリ一覧
-------------------------------- */

.cateall-sp {
  display: block;
  margin: 50px 0px 100px 0px;
  @include media_desktop {
    display: none;
  }
}

#category-spindex {
  @include media_desktop {
    display: none;
  }
}
.cateall-pc {
  display: none;
  @include media_desktop {
    display: block;
    margin: 50px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0;
  }
}

.cateall-pc > ul > li {
  margin-left: 100px !important;
  padding: 9px !important;
}

.cateall-pc > ul > li:before {
  content: "\f111";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}


.cateall-pc > ul > li > ul > li:before {
  content: "\f111";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.cateall-pc > ul > li > ul > li {
  margin-left: 40px !important;
  padding: 5px !important;

}
