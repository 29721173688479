// MediaQuery
$tablet: 480px;
$desktop: 768px;
$desktop2: 992px;
$desktop3: 1200px;

$font-size: 16px;

$clrRed: #DE5D50;
$clrRich: #9a947e;
$clrGray: #F3F3F3;
$clrRightGray: #B8BEC4;
$clrExtraGray: #636378;
$clrDarkGray:#525263;

//** bootstrap-sass(https://github.com/twbs/bootstrap-sass) からの移植

//$font-size-base: 0.875rem;
$font-family-sans-serif: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif !default;
$line-height-base: 1.4 !default;
$padding-base-vertical: 6px !default;
//$line-height-computed: floor(($font-size-base * $line-height-base)) px !default;
//$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2) px !default;
$input-border: #767676;
//** Border color for inputs on focus
$input-border-focus: #66afe9 !default;

//** Placeholder text color
$input-color-placeholder: #999 !default;

// scss-docs-start color-variables
$blue:    #337ab7 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red: #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
// scss-docs-end color-variables

$black: #292929;
$text-black: #292929;
$lightGray: #F5F5F5;// メガメニュー背景
$blueGray: #525263;// btn-default-color, text
$darkBrown: #3f2b16;
$orange: #ff9800;
$darkRed: #990000;

$border-color: #ccc;
$text-default-color: $blueGray;
$text-normalPrice-color: #ababab;
$text-beige: #9a947e;
$text-red: #cc0033;
$text-orange: $orange;
$text-link-color: #37a2c9;
$bg-red: $darkRed;

$padding-pc: 16px;
$padding-sp: 8px;
$max-width: calc(1150px + 2 * #{$padding-pc});
$opacity-default: 0.8;
$transition-default: all 0.3s ease 0s;
