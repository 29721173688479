@import "../mixins/projects";
@import "../mixins/media";

/* --------------------------------
	LINEバナー 20220725
-------------------------------- */
/*float_bnr*/
#float_bnr.hnb2207 {
  position: fixed;
  top: auto;
  bottom: 0;
  z-index: 999;
  @include media_desktop {
    right: 0;
    top: 296px;
  }

  .bnr {
    position: relative;
    padding: 1% 2%;
    background: rgba(0, 0, 0, 0.3);
    @include media_desktop {
      padding: 0;
      background: transparent;
    }

    img {
      display: block;
    }
    .img {
      margin: 0;
    }

    .close_btn {
      position: absolute;
      top: -10px;
      left: 2px;
      z-index: 10;
      margin: 0;
      @include media_desktop {
        left: -5px;
      }

      img {
        width: 20px;
      }
    }
  }
}
