@import "../mixins/media";

/*開閉アコーディオン開始*/
.accordionbox {
  width: 100%;
  padding: 30px 0 0 0;
}

.accordionlist {
  dt {
    display: block;
    border-top: 1px solid #DFDFDF;
    text-decoration: none;
    background: #f7f7f7;
    color: #555;
    font-weight: bold;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.29);
    position: relative;

    &:first-child {
      border-top: none !important;
    }

    .title {
      padding-left: 10px;
      float: left;
    }
  }

  dd {
    display: none;
    padding-top: 50px;
  }
}

.accordion_icon {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  position: relative;
  width: 30px;
  height: 15px;
  float: right;
  margin-right: 5px;
  margin-top: 20px;

  span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    left: 6px;
    width: 50%;
    height: 3px;
    background-color: #888;
    border-radius: 4px;

    &:nth-of-type(1) {
      top: 5px;
      transform: rotate(0deg);
    }

    &:nth-of-type(2) {
      top: 5px;
      transform: rotate(90deg);
    }
  }


  &.active span {
    &:nth-of-type(1) {
      display: none;
    }

    &:nth-of-type(2) {
      top: 5px;
      transform: rotate(180deg);
    }
  }
}

.accordionBtn {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 42px;
  padding-right: 15px;
  border: none;
  background: transparent;
  &:before {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #888;
    opacity: 0.7;
  }
  &:hover::before {
    opacity: 0.5;
  }
  &.active:before {
    content: "\f077";
  }

  #accordion & {
    line-height: 50px;
  }
}
