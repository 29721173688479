@import "../mixins/media";
@import "../mixins/projects";
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole{
  @include container;
}

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid{
  @include reset_link;
  @include hover_link-text;
  @include hover_link-img;
  display: flex;
  margin-inline: -$padding-sp;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  @include media_desktop {
    margin-inline: -$padding-pc;
  }
  & &__item{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 24px;
    padding-inline: 8px;
    @include media_desktop(){
      width: 25%;
      margin-bottom: 48px;
      padding-inline: 16px;
    }
    &-image {
      margin-bottom: 8px;
      text-align: center;
      @include media_desktop {
        margin-bottom: 15px;
      }
      img{
        display: block;
        width: 100%;
        max-height: 100%;
      }
    }
    &-name {
      margin-bottom: 8px;
      color: $black;
      font-size: 1.4rem;
      @include media_desktop {
        font-sizes: 1.6rem;
      }
    }
    &-desc {
      margin-bottom: 15px;
    }
    &-prices {
      color: $black;
    }
    &-priceRegular {
      color: $text-normalPrice-color;
    }
    &-price {
      color: $text-default-color;
      font-weight: bold;
    }
  }
  p.more_hnb {
    border: 1px solid $border-color;
    margin-top: 1em;
    padding: 8px 0;
    color: #333;
    text-align: center;
    font-size: 1.4rem;
    &:after {
      content: ">";
      display: inline-block;
      margin-left: 5px;
      font-size: 7px;
    }
    &:hover {
      opacity: $opacity-default;
    }
  }
}

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/
.ec-shelfGridCenter{
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center;

  @include media_desktop {
    margin-left: -16px;
    margin-right: -16px;
  }
  & &__item{
    margin-bottom: 36px;
    width: 50%;
    &-image {
      margin-bottom: 10px;
      text-align: center;
    }
    img{
      width: auto;
      max-height: 100%;
    }
    @include media_desktop(){
      padding: 0 16px;
      width: 25%;
    }

    .ec-productRole__btn {
      margin-top: auto;
      padding-top: 1em;
    }
  }
  & &__item:nth-child(odd){
    padding-right: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__item:nth-child(even){
    padding-left: 8px;
    @include media_desktop(){
      padding: 0 16px;
    }
  }
  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
}
