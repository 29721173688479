@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  @include container;
  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__profile {
    margin-left: 0;
    @include media_desktop {
      margin-left: calc(2 * #{$padding-pc});
    }
  }
  & &__title {
    .ec-headingTitle {
      margin-bottom: 5px;
      color: $black;
      font-weight: normal;
      line-height: inherit;
      font-size: 2rem;
      @include media_desktop {
        margin-bottom: 10px;
      }
    }
  }
  &__tags {
    margin-bottom: 10px;
  }
  & &__tag {
    display: inline-block;
    margin-bottom: 5px;
    padding-right: 5px;
  }
  & &__priceRegular {
    margin-top: 10px;
    color: $text-normalPrice-color;
    .small {
      font-weight: normal;
      @include media_desktop {
        font-size: 1.2rem;
      }
    }
  }
  &__price {
    margin-top: 10px;
    font-size: 2.6rem;
    @include media_desktop {
      font-size: 2.8rem;
    }
    .ec-price {
      color: $black;
      font-weight: bold;
      .small:first-child {
        margin-right: -5px;
      }
    }
    .small {
      font-size: 1.2rem;
      font-weight: normal;
      @include media_desktop {
        font-size: 1.6rem;
      }
    }
  }
  & &__code {
    padding: 8px 0;
    font-weight: bold;
    @include media_desktop {
      padding: 14px 0;
      border-top: 1px dotted $border-color;
      border-bottom: 1px dotted $border-color;
    }
  }
  & &__actions {
    padding-top: 8px;
    @include media_desktop {
      padding-top: 14px;
    }
    .ec-select {
      margin: 8px 0;
      @include media_desktop {
        margin: 14px 0;
      }
      select {
        height: 40px;
        max-width: 100%;
        @include media_desktop {
          max-width: 350px;
        }
      }
    }
    .ec-numberInput input {
      margin: 8px 0;
      @include media_desktop {
        margin: 14px 0;
      }
    }
  }
  .detail-deliver {
    padding: 10px 0;
  }
  & &__btnList {
    display: flex;
    gap: 20px;

    > * {
      width: 100%;
    }
    .ec-blockBtn {
      padding-inline: 0;
      font-size: 1.3rem;
      white-space: normal;
      @include media_desktop {
        font-size: 1.5rem;
      }
    }
  }
  & &__btn {
    width: 100%;
    margin-bottom: 20px;
    img:only-child {
      width: 100%;
    }
  }
}

/*
  商品説明　table
 */
.ec-productRole__description {
  @include clearfix;
  margin-bottom: 16px;
  &#freearea {
    margin: 16px auto;
    @include media_desktop {
      width: calc(100% * 10/12);
    }
  }
  p {
    margin: 10px 0 0 0;
  }

  #freearea-container {
    h3 {
      margin: 20px 0 20px 0;
      font-size: 2rem;
      font-weight: bold;
    }
    h4 {
      margin: 20px 0 20px 0;
      font-size: 1.8rem;
      font-weight: bold;
    }
    h5 {
      margin: 20px 0 20px 0;
      font-size: 1.6rem;
      font-weight: bold;
    }
    p {
      font-size: 1.5rem;
      line-height: 26px;
      margin: 5px 0 20px 0;
    }
  }


  /* --------------------------------
    商品詳細テーブル
  -------------------------------- */

  table.table01 {
    width:100%;
    border-right:#ccc solid 1px;
    border-collapse: collapse;
  }
  table.table01 thead th {
    background:#EEEEF0;
    color:#FFF;
    padding:10px 15px;
    border-right:#FFF solid 1px;
    border-bottom:#FFF solid 1px;
  }
  table.table01 thead th:last-child {
    border-right:#ccc solid 1px;
  }
  table.table01 tbody th {
    background:#EEEEF0;
    padding: 5px;
    border: 1px solid #ccc;
    color: #777;
    width: 20%;
    text-align: left;
    font-weight: normal;
  }
  table.table01 tbody tr:last-child th {
    border-bottom:#ccc solid 1px;
  }
  table.table01 tbody td {
    padding: 5px;
    border: 1px solid #ccc;
    color: #777;
  }

  @media screen and (max-width: 640px) {
    table.table01 thead {
      display:none;
    }
    table.table01 tbody th {
      display:block;
      width: 100%;
    }
    table.table01 tbody td {
      display:block;
    }
    table.table01 tbody td::before {
      content: attr(label);
      float: left;
      clear:both;
      font-weight:bold;
    }
    table.table01 tbody td p {
      padding-left:6em;
    }

  }

  table.table02 {
    width:100%;
    border-right:#ccc solid 1px;
    border-collapse: collapse;
  }
  table.table02 thead th {
    background:#625750;
    color:#FFF;
    padding:10px 15px;
    border-right:#FFF solid 1px;
    border-bottom:#FFF solid 1px;
  }
  table.table02 thead th:last-child {
    border-right:#ccc solid 1px;
  }
  table.table02 tbody th {
    background:#625750;
    color:#FFF;
    padding:10px 15px;
    border:#FFF solid 1px;
    vertical-align:top;
  }
  table.table02 tbody tr:last-child th {
    border-bottom:#ccc solid 1px;
  }
  table.table02 tbody td {
    background:#FFF;
    padding:10px 15px;
    border-left:#ccc solid 1px;
    border-bottom:#ccc solid 1px;
    border-top:#ccc solid 1px;
    vertical-align:top;
  }

  @media screen and (max-width: 640px) {
    table.table02 thead {
      display:none;
    }
    table.table02 tbody th {
      display:block;
    }
    table.table02 tbody td {
      display:block;
    }
    table.table02 tbody td::before {
      content: attr(label);
      float: left;
      clear:both;
      font-weight:bold;
    }
    table.table02 tbody td p {
      padding-left:6em;
    }

  }

  table.table-std {
    width:100%;
    margin-bottom: 20px;
  }
  table.table-std thead th {
    background:#EEEEF0;
    padding: 5px;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: #ccc;
    color: #777;
  }
  table.table-std thead th:last-child {
    border-right:#ccc solid 1px;
  }
  table.table-std tbody th {
    background:#EEEEF0;
    padding: 5px;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #ccc;
    color: #777;
    width: 20%;
    text-align: left;
    font-weight: normal;
  }
  table.table-std tbody tr:last-child th {
    border-bottom:#ccc solid 1px;
  }
  table.table-std tbody td {
    padding: 5px;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: #ccc;
    color: #777;
  }

  @media screen and (max-width: 640px) {
    table.table-std {
      width:100%;
      border-width: 1px 1px 1px 1px;
      border-style: solid;
      border-color: #ccc;
    }

    table.table-std thead {
      display:none;
    }
    table.table-std tbody th {
      display:block;
      width: 100%;
      border-width: 1px 0 0 0;
      border-style: solid;
      border-color: #ccc;
    }
    table.table-std tbody td {
      display:block;
      border-width: 1px 0 0 0;
      border-style: solid;
      border-color: #ccc;
    }
    table.table-std tbody td::before {
      content: attr(label);
      float: left;
      clear:both;
      font-weight:bold;
    }
    table.table-std tbody td p {
      padding-left:6em;
    }
    table.table-std tbody tr:first-child th {
      border-width: 0 0 0 0;
      border-style: solid;
      border-color: #ccc;
    }

    table.table-std tbody tr:last-child th {
      border-width: 1px 0 0 0;
      border-style: solid;
      border-color: #ccc;
    }
  }

  .key {
    color: rgb(220, 204, 180);
    font-size: 1.2rem;
  }

  .container-fluid:before, .container-fluid:after, .row:before, .row:after {
    content: " ";
    display: table;
  }
  .container-fluid {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .row {
    margin-left: -8px;
    margin-right: -8px;
    padding-bottom: 15px;
  }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 8px;
    padding-right: 8px;
  }

  @media only screen and (min-width: 768px) {
    .container-fluid {
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      padding-right: 0;
    }
    .row {
      margin-left: -16px;
      margin-right: -16px;
      padding-bottom: 16px;
    }
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      padding-left: 16px;
      padding-right: 16px;
    }
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
      float:left
    }
    .col-sm-12 {
      width: 100%
    }
    .col-sm-11 {
      width: 91.66666667%
    }
    .col-sm-10 {
      width: 83.33333333%
    }
    .col-sm-9 {
      width: 75%
    }
    .col-sm-8 {
      width: 66.66666667%
    }
    .col-sm-7 {
      width: 58.33333333%
    }
    .col-sm-6 {
      width: 50%
    }
    .col-sm-5 {
      width: 41.66666667%
    }
    .col-sm-4 {
      width: 33.33333333%
    }
    .col-sm-3 {
      width: 25%
    }
    .col-sm-2 {
      width: 16.66666667%
    }
    .col-sm-1 {
      width: 8.33333333%
    }
    .col-sm-pull-12 {
      right: 100%
    }
    .col-sm-pull-11 {
      right: 91.66666667%
    }
    .col-sm-pull-10 {
      right: 83.33333333%
    }
    .col-sm-pull-9 {
      right: 75%
    }
    .col-sm-pull-8 {
      right: 66.66666667%
    }
    .col-sm-pull-7 {
      right: 58.33333333%
    }
    .col-sm-pull-6 {
      right: 50%
    }
    .col-sm-pull-5 {
      right: 41.66666667%
    }
    .col-sm-pull-4 {
      right: 33.33333333%
    }
    .col-sm-pull-3 {
      right: 25%
    }
    .col-sm-pull-2 {
      right: 16.66666667%
    }
    .col-sm-pull-1 {
      right: 8.33333333%
    }
    .col-sm-pull-0 {
      right: auto
    }
    .col-sm-push-12 {
      left: 100%
    }
    .col-sm-push-11 {
      left: 91.66666667%
    }
    .col-sm-push-10 {
      left: 83.33333333%
    }
    .col-sm-push-9 {
      left: 75%
    }
    .col-sm-push-8 {
      left: 66.66666667%
    }
    .col-sm-push-7 {
      left: 58.33333333%
    }
    .col-sm-push-6 {
      left: 50%
    }
    .col-sm-push-5 {
      left: 41.66666667%
    }
    .col-sm-push-4 {
      left: 33.33333333%
    }
    .col-sm-push-3 {
      left: 25%
    }
    .col-sm-push-2 {
      left: 16.66666667%
    }
    .col-sm-push-1 {
      left: 8.33333333%
    }
    .col-sm-push-0 {
      left: auto
    }
    .col-sm-offset-12 {
      margin-left: 100%
    }
    .col-sm-offset-11 {
      margin-left: 91.66666667%
    }
    .col-sm-offset-10 {
      margin-left: 83.33333333%
    }
    .col-sm-offset-9 {
      margin-left: 75%
    }
    .col-sm-offset-8 {
      margin-left: 66.66666667%
    }
    .col-sm-offset-7 {
      margin-left: 58.33333333%
    }
    .col-sm-offset-6 {
      margin-left: 50%
    }
    .col-sm-offset-5 {
      margin-left: 41.66666667%
    }
    .col-sm-offset-4 {
      margin-left: 33.33333333%
    }
    .col-sm-offset-3 {
      margin-left: 25%
    }
    .col-sm-offset-2 {
      margin-left: 16.66666667%
    }
    .col-sm-offset-1 {
      margin-left: 8.33333333%
    }
    .col-sm-offset-0 {
      margin-left: 0
    }
  }

  .tabbox {
    margin: 0;
    background: #fff;

    /* ▼タブ機能を制御するラジオボタン(非表示にする) */
    input {
      display: none;
    }
    /* ▼タブ機能の掲載領域の装飾(※必須ではありません) */
    h5 {
      display: block;
      -webkit-margin-before: 1.67em;
      -webkit-margin-after: 1.67em;
      -webkit-margin-start: 0;
      -webkit-margin-end: 0;
      margin: 16px;
      font-size: 2.3rem;
      font-weight: bold;
    }
  }

  /* ▼タブ(共通装飾＋非選択状態の装飾) */
  .tab-discription {
    float: left;
    position: relative;
    display: inline-block;
    width: 33.33%;
    height: 49px;
    padding: 0.75em 1em;
    border: 1px solid #ccc;
    background: #EEEEF0;
    color: #777;
    font-size: 1.6rem;
    text-align: center;
    z-index: 1;

    /* ▼タブにマウスポインタが載った際の装飾 */
    &:hover {
      background: #777;
      color: #fff;
    }
  }

  /* ▼チェックが入っているラジオボタンの隣にあるタブの装飾(＝選択状態のタブ) */
  input:checked + .tab-discription {
    background: #fff;
    position: relative;
    z-index: 1;
    color: #777;
    border-bottom-width: 0;
    height: 49px;
  }

  /* ▼タブの中身(共通装飾＋非選択状態の装飾) */
  .tabcontent {
    display: none;
    position: relative;
    margin-top: -1px;
    padding: 1em;
    border: 1px solid #ccc;
    background: #fff;
    z-index: 0;
  }

  #freearea-container .tabcontent{
    top: 48px;
    margin-bottom: 50px;
    padding: 50px 20px 10px;
    @include media_desktop {
      padding: 50px 30px 10px;
    }
  }

  /* ▼チェックが入っているラジオボタンに対応するタブの中身を表示する */
  @for $i from 1 through 8 {
    #tabcheck#{$i}:checked ~ #tabcontent#{$i} {
      display: block;
    }
  }

  #link-hide-active {
    display: none;
  }

  #kanren-hide-active {
    display: none;
  }
}

/*
  photoswipe
 */
#detail_photoswipe a {
  display: block;
  margin-bottom: 30px;
}
