@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/clearfix";
@import "../mixins/animation";

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  padding-top: 160px;
  transition: transform 0.3s;
  background: #fff;
  @include media_desktop {
    padding-top: 0;
  }
  .have_curtain & > *{
    transform: translateX(-260px);
  }
  &__header {
    background: #fff;
    z-index: 2;
    position: fixed;
    top: 0;
    transition: $transition-default;
    @include media_desktop {
      position: relative;
    }
    &.hide {
      transform: translateY(-400%);
      @include media_desktop {
        transform: translateY(0);
      }
    }
  }
  & &__contentTop {
    padding: 0;
  }
  & &__contents {
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    width: 100%;
    max-width: $max-width;
    margin-right: auto;
    margin-left: auto;
    @include media_desktop {
      padding-top: 30px;
    }
  }
  & &__main {
    width: 100%;
  }
  & &__mainWithColumn {
    width: 100%;
    @include media_desktop() {
      width: 75%;
    }
  }
  & &__mainBetweenColumn {
    width: 100%;
    @include media_desktop() {
      width: 50%;
    }
  }
  & &__left,
  & &__right {
    display: none;
    @include media_desktop() {
      display: block;
      width: 25%;
    }
  }
  & &__left {
    padding-inline: $padding-pc $padding-sp;
  }
}


.ec-headerRole {
  @include container;
  padding-top: 15px;
  position: relative;
  &:after {
    display: none;
  }
  @include media_desktop {
    @include clearfix;
  }
  &::before {
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
  @include media_desktop {
    width: 100%;
    @include clearfix;
  }
  & &__title {
    width: 100%;
  }
  & &__navSP {
    display: block;
    position: absolute;
    top: 15px;
    width: 27%;
    right: 0;
    text-align: right;
    @include media_desktop {
      display: none;
    }
  }
}

.ec-headerNaviRole {
  @include container;
  h1 {
    font-size: 12px;
    font-weight: normal;
    margin: 10px 0 0px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  &__title {
    width: 50%;
    @include media_desktop {
      width: 30%;
    }
  }
  &__search {
    display: none;
    @include media_desktop() {
      display: block;
      width: 25%;
    }
  }
  &__other {
    display: flex;
    justify-content: end;
    width: 50%;
    @include media_desktop {
      display: block;
      width: 45%;
      text-align: right;
    }
  }
  & &__nav {
    display: none;
    @include media_desktop {
        display: inline-block;
        @include reset_link;
    }
  }
  & &__cart {
    display: inline-block;
    @include reset_link;
  }
  & &__navSP {
    @include media_desktop {
      display: none;
    }
  }
}

.ec-headerNavSP {
  display: flex;
  align-items: center;
  font-size: 3rem;
  transform: translateY(2px);
  &__favBtn {
    display: flex;
    width: 44px;
    height: 44px;
    img {
      display: block;
      margin: auto;
      width: 1em;
      height: 1em;
    }
  }
  &__menuBtn {
    position: relative;
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    background: transparent;
    width: 44px;
    height: 44px;
    color: $text-default-color;
    @include media_desktop {
      display: none;
    }
    span {
      position: relative;
      margin: auto;
      height: 0.1em;
      width: 1em;
      background: currentColor;
      transition: $transition-default;
      &:before,
      &:after {
        content: '';
        position: absolute;
        right: 0;
        display: inline-block;
        height: 0.1em;
        width: 1em;
        background: currentColor;
        transform: translateZ(0);
        backface-visibility: hidden;
        transform-origin: 0% 50%;
        transition: $transition-default;
      }
      &:before {
        top: -8px;
      }
      &:after {
        top: 8px;
      }
    }
    .have_curtain & {
      span {
        background: transparent;
        &:before {
          transform: translateX(4px) translateY(-3px) rotate(45deg);
        }
        &:after {
          transform: translateX(4px) translateY(2px) rotate(-45deg);
        }
      }
    }
  }
}
.ec-headerNavSP.is-active {
  //display: none;
}

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
  @include commonStyle();
  & &__title {
    text-align: center;
    h1 {
      margin: 0;
      padding: 0;
    }
    a {
      display: inline-block;
      margin-bottom: 30px;
      text-decoration: none;
      font-size: 20px;

      @include media_desktop() {
        font-size: 40px;
      }
      font-weight: bold;
      color: black;

      &:hover {
        opacity: .8;
      }
    }
  }
  & &__subtitle {
    font-size: 10px;
    text-align: center;
    @include media_desktop() {
      font-size: 16px;
      margin-bottom: 10px;
    }
    a {
      display: inline-block;
      color: #0092C4;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNav {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  @include media_desktop {
    flex-direction: row;
    margin-bottom: 0;
  }
  & &__item {
    margin-left: 0;
    display: inline-block;
    a {
      display: flex;
      align-items: center;
      height: 5rem;
      padding: 0 10px;
      transition: $transition-default;
      color: $black;
      @include media_desktop {
        height: auto;
        color: $darkBrown;
      }
      &:hover {
        color: #9797a1;
      }
    }
  }
  & &__itemIcon {
    display: flex;
    flex-shrink: 0;
    margin-right: 5px;
    font-size: 3rem;
    img {
      width: 1em;
      height: 1em;
    }
  }
  & &__itemLink {
    color: currentColor;
    font-size: 1.4rem;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch{
  @include clearfix;
  & &__category {
    float: none;
    @include media_desktop {
      float: left;
      width: 43%;
    }
    .ec-select {
      overflow: hidden;
      width: 100%;
      margin: 0;
      text-align: center;

      select {
        width: 100%;
        cursor: pointer;
        padding: 8px 24px 8px 8px;
        text-indent: 0.01px;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: transparent;
        background-image: none;
        box-shadow: none;
        appearance: none;
        color: #fff;

        @include media_desktop {
          max-width: 165px;
          height: 36px;
        }

        option {
          color: #000;
        }

        &::-ms-expand {
          display: none;
        }
      }

      &.ec-select_search {
        position: relative;
        border: 0;
        background: #000;
        color: #fff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        @include media_desktop {
          border-top-right-radius: inherit;
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
        }

        &::before {
          position: absolute;
          top: 0.8em;
          right: 0.4em;
          width: 0;
          height: 0;
          padding: 0;
          content: '';
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #fff;
          pointer-events: none;
        }
      }
    }
  }
  & &__keyword{
    position: relative;
    color: $clrDarkGray;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    @include media_desktop {
      float: right;
      width: 100%;
    }
    .ec-input {
      display: flex;
      justify-content: space-between;
    }
    input[type="search"]{
      width: calc(100% - 40px);
      height: 32px;
      font-size: 16px;
      border: 1px solid #ccc;
      padding: 0.5em 1em;
      box-shadow: none;
      box-sizing: border-box;
      margin-bottom: 0;
      @include media_desktop {
        font-size: 12px;
      }
    }
  }
  & &__keywordBtn{
    display: block;
    background: #787878;
    height: 32px;
    width: 32px;
    margin: 0;
    padding: 0;
    border: 0;
    white-space: nowrap;

    .ec-icon {
      display: flex;
      width: 15px;
      height: 15px;
      margin: auto;
    }
    svg {
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }
}

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole,
.ec-headerCategoryArea {
  width: 100%;
  margin-bottom: 20px;
  @include reset_link;
  &__heading {
    text-align: center;
    height: 50px;
    padding-top: 14px;
    background-color: #837d79;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    font-family: "Segoe UI", Verdana, "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  }
}

.ec-itemNav {
  padding: 0;
  width: 100%;
  height: 100%;
  border: solid 1px #bcbcbc;
  ~ p {
    margin: 1em 0;
  }
}

.ec-itemNav__nav {
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

.ec-itemNav__nav__imgLink {
  margin: 1em 0;
}

.ec-itemNav__nav li {
  width: 100%;
  position: relative;
  border-top: 1px solid #ddd;
}

.ec-itemNav__nav li a {
  position: relative;
  display: block;
  padding: 11.5px 22px;
  color: #000;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Segoe UI", Verdana, "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  &:hover {
    color: #ada29b;
  }
}

.ec-itemNav__nav li ul {
  list-style: none;
  display: none;
  margin: 0;
  padding: 0;
}

.ec-itemNav__nav li ul li {
  width: 100%;
  position: relative;
  border-top: 1px solid #ddd;
}

@function make_selector_children($max) {
  $selector_list: [];
  @for $i from 1 through $max {
    $selector_list: append($selector_list, ' ul li');
  }
  $s: '';
  @each $selector in $selector_list {
    $s: $s + #{$selector};
  }
  @return $s;
}
@function make_selector($max) {
  $s: make_selector_children($max);
  $selector: '.ec-itemNav__nav li' + $s + ' a';
  @return $selector;
}
@for $i from 1 through 5 {
  $selector: make_selector($i);
  #{$selector} {
    padding-left: calc(1.5em + #{$i} * 1em);
  }
}

.ec-itemNav__nav > li:hover > ul > li {
  @include media_desktop {
    overflow: visible;
    height: auto;

  }
}

.ec-itemNav__nav li ul li ul {
  top: 0;
  left: 100%;
  width: auto;
}

.ec-itemNav__nav li ul li ul:before {
  @include media_desktop {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: white;
    position: absolute;
    top: 19px;
    right: auto;
    left: -20px;
  }
}

.ec-itemNav__nav li ul li:hover > ul > li {
  @include media_desktop {
    overflow: visible;
    height: auto;
    width: auto;
  }
}

#category-spindex {
  .ec-categoryNaviRole {
    margin-bottom: 0;
  }
  @include media_desktop {
    display: none;
  }
}

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/

.ec-drawerRole {
  overscroll-behavior-y: contain;
  overflow-y: scroll;
  width: 260px;
  height: 100vh;
  background: #fff;
  transform: translateX(100%);
  position: fixed;
  top: 0;
  right: 0;
  transition: $transition-default;
  @include media_desktop() {
    display: none;
  }


  .ec-headerSearchArea {
    padding: 20px 10px;
    width: 100%;
    background: #F8F8F8;
  }

  .ec-headerSearch{
    padding: 20px 10px 5px 10px;
    select{
      width: 100% !important;
    }
    &__keyword input[type="search"] {
      height: auto;
      padding: 9px 10px 13px 10px;
      width: calc(100% - 50px);
    }
    &__keywordBtn {
      height: 45px;
      width: 45px;

      .ec-icon {
        width: 22px;
        height: 22px;
      }
    }
  }

  .ec-categoryNaviRole,
  .ec-headerCategoryArea {
    margin-bottom: 0;
  }
}

.ec-drawerRoleClose {
  display: none;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 270px;
  z-index: 1000;

  .fas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include media_desktop {
    display: none;
  }

}

.ec-drawerRole.is_active {
  display: block;
  transform: translateX(0);
  transition: all .3s;
  z-index: 100000;

  @include media_desktop() {
    display: none;
  }
}
.ec-drawerRoleClose.is_active  {
  display: inline-block;
  transition: all .3s;

  @include media_desktop {
    display: none;
  }
}

.ec-overlayRole {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all .3s;
  visibility: hidden;

  @include media_desktop {
    display: none;
  }
}

.have_curtain .ec-overlayRole {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible;

  @include media_desktop {
    display: none;
  }
}

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/

.ec-itemNavAccordion {
  display: none;
}

.ec-maintenanceAlert {
  background: steelblue;
  height: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  z-index: 9999;
  display: flex;
  font-weight: bold;
  & > * {
    margin: auto;
  }
  & &__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top;
  }
  & +* {
    margin-top: 50px;
  }
  }
