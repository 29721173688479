@import "../mixins/media";

/*------------------------------------
2022.9.29 henobu はじめてのラグ選び
------------------------------------*/

.first_rug {
  .mb10 {
    margin-bottom: 10px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .mb40 {
    margin-bottom: 40px;
  }

  .mb60 {
    margin-bottom: 40px;
    @include media_desktop {
      margin-bottom: 60px;
    }
  }

  .mb80 {
    margin-bottom: 80px;
  }

  .mb100 {
    margin-bottom: 60px;
    @include media_desktop {
      margin-bottom: 100px;
    }
  }

  .center {
    text-align: center;
  }
  .btn {
    font-size: 1.6rem;
    padding: 10px 16px;
    font-weight: bold;
  }/*
  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0;
  }*/
  img.pc {
    width: 100%;
  }

  .flex50 {
    display: block;
    @include media_desktop {
      display: flex;
      gap: 50px;
    }

    p {
      width: 100%;
      margin-bottom: 40px;
      @include media_desktop {
        width: 50%;
        margin-bottom: 0;
      }
    }
  }

  span.text_bk {
    background: #F1DDD6;
    padding: 0 2px;
  }

  .pc_btn {
    width: 100%;
    margin: 0 auto;
    @include media_desktop {
      width: 350px;
    }
  }

  h2 {
    &.text {
      text-align: center;
      font-size: 2.3rem;
      margin: 60px 0 40px;
      font-weight: bold;
    }

    &.bk {
      text-align: center;
      font-size: 1.2rem;
      margin: 0 0 40px;
      color: #fff;
      background: #B94836;
      padding: 20px 0 30px;
      font-weight: normal;

      span {
        display: block;
        font-size: 2.3rem;
        @include media_desktop {
          font-size: 2.4rem;
        }
      }
    }
  }

  .flex_sec2 {
    display: block;
    @include media_desktop {
      display: flex;
      gap: 50px;
      margin-top: 30px;
    }

    .box {
      width: 100%;
      margin-bottom: 40px;
      @include media_desktop {
        width: 50%;
        margin-bottom: 0;
      }

      .img {
        margin-bottom: 20px;
      }

      h3 {
        &.bk {
          text-align: center;
          background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images/first_rug/bk_h3.jpg?6) no-repeat 0 center;
          font-size: 1.8rem;
          background-size: contain;
          @include media_desktop {
            background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images/first_rug/bk_h3.jpg?5) no-repeat 0 center;
            background-size: auto;
          }
        }

        &.bk2 {
          text-align: center;
          background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images/first_rug/bk_h3_2.jpg?6) no-repeat 0 center;
          font-size: 1.8rem;
          background-size: contain;
          @include media_desktop {
            background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images/first_rug/bk_h3_2.jpg?5) no-repeat 0 center;
            background-size: auto;
          }
        }
      }
    }
  }

  .movie {
    width: 100%;
    margin: 0 auto 40px;
    @include media_desktop {
      width: 80%;
      margin-bottom: 60px;
    }
  }

  .flex_nav {
    ul {
      display: block;
      @include media_desktop {
        display: flex;
        gap: 30px;
      }
    }

    li {
      width: 80%;
      margin: 0 auto 20px;
      @include media_desktop {
        width: 33.33%;
        margin: 0;
      }
      a {
        display: block;
        border: 1px solid #333;
        padding: 10px 20px 10px 40px;
        background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images/first_rug/icon.png) no-repeat 30px center;
        background-size: 6px;
      }
    }
  }

  .flex_nav2 {
    display: block;
    width: 90%;
    margin: 0 auto 60px;
    font-size: 1.6rem;
    @include media_desktop {
      display: flex;
      gap: 80px;
    }

    .box {
      width: 100%;
      margin-bottom: 20px;
      @include media_desktop {
        width: 50%;
        margin-bottom: 0;
      }
    }
  }

  .bk_gl {
    background: #F5F5F5;
    padding: 20px 40px 40px 40px;
    border-radius: 30px;

    h3 {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #333;
    }

    .flex_gl {
      display: block;
      @include media_desktop {
        display: flex;
        gap: 30px;
      }

      .img, .text {
        width: 100%;
        @include media_desktop {
          width: 50%;
        }
      }
      .img {
        margin-bottom: 20px;
        @include media_desktop {
          margin-bottom: 0;
        }
      }

      .btn {
        a {
          display: inline-block;
          border: 1px solid #333;
          padding: 10px 20px 10px 40px;
          background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images/first_rug/icon.png) no-repeat 30px center;
          background-size: 6px;
        }

        display: block;
        margin: 0 auto;
      }
    }
  }

  h4.sar {
    text-align: center;

    span {
      background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images/first_rug/icon_sar.jpg) no-repeat 0 center;
      text-align: center;
      background-size: 1em;
      padding-left: 2.5rem;
    }
  }
  &.hnb_top_2208 {
    p {
      margin: 0;
      line-height: 2.7rem;
    }
    .flex3wrap {
      margin-bottom: 80px;
      @include media_desktop {
        gap: 20px;
        flex-wrap: nowrap;
      }

      .item {
        @include media_desktop {
          width: 33.33%;
        }

        .text.on {
          @include media_desktop {
            bottom: -1rem;
            background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images/first_rug/icon_w.png) no-repeat 95% center;
            background-size: 6px;
          }
          span {
            margin-bottom: 0;
          }
          &br {
            bottom: -2.5rem;
          }
        }
        a {
          background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images/first_rug/icon.png) no-repeat 95% center;
          background-size: 6px;
          @include media_desktop {
            background: none;
          }
        }
      }
    }

    .item.one {
      width: 100%;
      margin: 0 auto 60px;
      @include media_desktop {
        width: 65%;
      }

      .text.on {
        @include media_desktop {
          bottom: -1rem;
          background: url(https://hx6olrysvb.user-space.cdn.idcfcloud.net/images/first_rug/icon_w.png) no-repeat 95% center;
          background-size: 6px;
        }
      }
    }
  }
}





@media screen and (max-width:767px) {

//.mb0 img{line-hei ght: 0}

}
