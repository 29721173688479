@import "../mixins/media";
@import "../mixins/clearfix";

/*
見出し

トップページで使用されているカレンダーのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.9.1
*/

/* 背景や文字の色調整 */
$calander-default-bg: #fff;//月〜金までの背景色
$calander-default-color: inherit;//月〜金までの文字色
$calander-sun-bg: #fff;//日曜の背景色
$calander-sun-color: #ff3300;//日曜の文字色
$calander-sat-bg: #fff;//土曜の背景色
$calander-sat-color: #3366cc;//土曜の文字色

$calander-day-bg: #FFF;//日付の背景色
$calander-day-color: #554130;//日付の文字色
$calander-holiday-bg: $orange;//休日の背景色=「出荷日」
$calander-holiday-color: #fff;//休日の文字色=「出荷日」
$calander-today-bg: #fff;//本日の背景色
$calander-today-color: #554130;//本日の文字色

$calander-padding: 8px;//カレンダーの数字周りの余白

$calander-border: #f3f3f3;//カレンダーの線の色



.ec-calendar{
  margin: 50px 0 20px;

  &__header {
    font-weight: bold;
    padding-left: 10px;
  }
  &__month{
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
    th,td{
      text-align: center;
    }//th,td
    td {
      border-bottom: 1px dotted #CCCCCC;
    }
  }//.ec-calendar__month
  & &__title{
    border: 0;
    font-weight: normal;
  }//.ec-calendar__title
  & &__sun{
    background: $calander-sun-bg;
    color: $calander-sun-color;
  }//.ec-calendar__sun
  & &__mon,
  & &__tue,
  & &__wed,
  & &__thu,
  & &__fri{
    background: $calander-default-bg;
    color: $calander-default-color;
  }
  & &__sat{
    background: $calander-sat-bg;
    color: $calander-sat-color;
  }//.ec-calendar__sat
  & &__day{
    background: $calander-day-bg;
    color: $calander-day-color;
  }//.ec-calendar__day
  & &__holiday{
    background: $calander-holiday-bg;
    color: $calander-holiday-color !important;
  }//.ec-calendar__holiday
  & &__today{
    color: $calander-today-color;
  }//.ec-calendar__today

}//.ec-calendar
