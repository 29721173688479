@import "../mixins/media";
/*
 mainBottom
 ページ下インフォメーション bottomInfo.twig
 */
.bottomInfo {
  @include container;
  display: flex;
  flex-wrap: wrap;
  @include hover_link-img;
  h5 {
    width: 100%;;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    font-size: 0.83em;
    font-weight: bold;
  }
  &__inner {
    width: 100%;
    padding-inline: $padding-sp;
    @include media_desktop {
      width: calc(100% / 3);
      padding-inline: $padding-pc;
    }
  }
  .item_name {
    display: block;
    margin: 0 0 8px;
    color: $text-beige;
    font-size: 1.4rem;
    @include media_desktop {
      font-size: 1.6rem;
    }
    p {
      margin-bottom: 8px;
    }
  }
  .item_photo {
    display: block;
    margin-bottom: 8px;
    text-align: center;
    @include media_desktop {
      margin-bottom: 15px;
    }
  }
  .item_comment {
    display: flex;
    margin-bottom: 8px;
    color: #444;
    @include media_desktop {
      margin-bottom: 15px;
    }
  }
  p {
    margin: 0;
  }
  img {
    //display: block;
  }
  .showroom {
    @include hover_link-text;
  }
  .comodo {
    font-size: 1.3rem;
    color: $text-beige;
  }
}
