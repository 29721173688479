@import "../mixins/clearfix";
@import "../mixins/media";

@mixin row{
  display: block;
  margin: 0;
  @include media_desktop {
    display: flex;
  }
}

@mixin makeSmColumn($columns){
  position: relative;
  min-height: 1px;

  @media (min-width: $desktop) {
    width: calc($columns/ 12 * 100%);
  }
  @include media_desktop{
  }

}

/*
グリッド

画面を12分割し、グリッドレイアウトに対応するためのスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.1
*/

/*
2分割グリッド

画面 ２分割の　グリッドです。
Bootstrap の col-sm-6 相当のグリッドを提供します。

Markup:
.ec-grid2
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 5.1.1
*/
.ec-grid2{
  @include row;
  & &__cell{
    @include makeSmColumn(6);
  }
  & &__cell2{
    @include makeSmColumn(12);
  }
}
/*
3分割グリッド

画面　３分割の　グリッドです。


Markup:
.ec-grid3
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell

Styleguide 5.1.2
*/
.ec-grid3{
  @include row;
  & &__cell{
    @include makeSmColumn(4);
  }
  & &__cell2 {
    @include makeSmColumn(8);
  }
  & &__cell3 {
    @include makeSmColumn(12);
  }
}

/*
4分割グリッド

画面　４分割の　グリッドです。


Markup:
.ec-grid4
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell

Styleguide 5.1.3
*/
.ec-grid4{
  @include row;
  & &__cell{
    @include makeSmColumn(3);
  }
}

/*
6分割グリッド

2つにまとめた cell2 や 3つをまとめた cell3 タグも使用可能です。


Markup:
.ec-grid6
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
.ec-grid6
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
.ec-grid6
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3

Styleguide 5.1.4
*/
.ec-grid6{
  @include row;
  & &__cell{
    @include makeSmColumn(2);
  }
  & &__cell2{
    @include makeSmColumn(4);
  }
  & &__cell3{
    @include makeSmColumn(6);
  }
}

/*
中央寄せグリッド 10/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の１０グリッドです

ex [ご利用規約ページ　本文](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-off1Grid
  .ec-off1Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.5
*/
.ec-off1Grid{
  margin: 0;
  @include media_desktop {
    @include row;
  }
  & &__cell{
    margin: 0;
    @include media_desktop {
      @include makeSmColumn(10);
      margin-left: calc(1 / 12 * 100%);
    }
  }
}


/*
中央寄せグリッド 8/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の８グリッドです


Markup:
.ec-off2Grid
  .ec-off2Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.6
*/
.ec-off2Grid{
  @include row;
  & &__cell{
    margin: 0;
    @include media_desktop {
      @include makeSmColumn(8);
      margin-left: calc(2 / 12 * 100%);
    }
  }
}
/*
中央寄せグリッド 6/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の６グリッドです


Markup:
.ec-off3Grid
  .ec-off3Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.7
*/
.ec-off3Grid{
  @include row;
  & &__cell{
    margin: 0;
    @include media_desktop {
      @include makeSmColumn(6);
      margin-left: calc(3 / 12 * 100%);
    }
  }
}
/*
中央寄せグリッド 4/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の４グリッドです


Markup:
.ec-off4Grid
  .ec-off4Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod


Styleguide 5.1.8
*/
.ec-off4Grid{
  @include row;
  & &__cell{
    margin: 0;
    @include media_desktop {
      @include makeSmColumn(4);
      margin-left: calc(4 / 12 * 100%);
    }
  }
}

/*
グリッドオプション

グリッドのセルに対して「左寄せ」「中央寄せ」「右寄せ」のオプションを付与することができます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 5.1.9
*/

/*
グリッドセルの左寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--left
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.10
*/
.ec-grid--left {
  justify-content: flex-start;
}
/*
グリッドセルの右寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--right
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.11
*/
.ec-grid--right {
  justify-content: flex-end;
}
/*
グリッドセルの中央寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--center
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.12
*/
.ec-grid--center {
  justify-content: center
}
